import { isEqual } from "date-fns";

export function dateIsEqual(
  a: Date | null | undefined,
  b: Date | null | undefined,
) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return isEqual(
    new Date(
      a.getFullYear(),
      a.getMonth(),
      a.getDate(),
      a.getHours(),
      a.getMinutes(),
      0,
      0,
    ),
    new Date(
      b.getFullYear(),
      b.getMonth(),
      b.getDate(),
      b.getHours(),
      b.getMinutes(),
      0,
      0,
    ),
  );
}

export function dateIsSameDay(
  a: Date | null | undefined,
  b: Date | null | undefined,
) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return isEqual(
    new Date(a.getFullYear(), a.getMonth(), a.getDate(), 0, 0, 0, 0),
    new Date(b.getFullYear(), b.getMonth(), b.getDate(), 0, 0, 0, 0),
  );
}
