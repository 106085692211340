import { Time } from "@internationalized/date";

import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import { de } from "date-fns/locale";

export function formatDateTime(
  date: Date | string | number,
  formatString = "PPPp",
  timezone = "Europe/Berlin",
  locale = de,
) {
  try {
    const str = formatInTimeZone(date, timezone, formatString, { locale });
    return str;
  } catch {
    return "";
  }
}

export function formatDate(
  date: Date | string | number,
  formatString = "PP",
  timezone = "Europe/Berlin",
  locale = de,
) {
  try {
    const str = formatInTimeZone(date, timezone, formatString, { locale });
    return str;
  } catch {
    return "";
  }
}

export function formatDateISO(
  date: Date | string | number,
  timezone = "Europe/Berlin",
  locale = de,
) {
  return formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", timezone, locale);
}

export function timeFromDate(
  date: Date | string | number,
  timezone = "Europe/Berlin",
) {
  try {
    const zonedDate = toZonedTime(date, timezone);
    const time = new Time(zonedDate.getHours(), zonedDate.getMinutes());
    return time;
  } catch {
    return undefined;
  }
}
